import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import { NavLink } from 'react-router-dom';

export default function Openings() {
  return (
    <>
      <Helmet>
        <title>Job Opening</title>
        <meta name="description" content="Job Opening" />
      </Helmet>
      <InnerBanner
        InnBanner="images/find-your-job-banner.jpg"
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg="images/find-your-job-right-banner.png"
        BnrLeftImg=""
        TitleInn={['Find ', <span>Your Job</span>]}
        ParaInn=""
        priCTALink={''}
        priCTATitle={''}
        secCTALink={''}
        secCTATitle={''}
      />
      <section className="full_width fix_padding">
        <div className="container">
          <div className="row">
            <div className="text_controller text_controller_center">
              <h2>
                {' '}
                Urgent Opening For –<span> IT Business Analyst</span>{' '}
              </h2>
              <hr />
            </div>
          </div>
          <div className="job-card">
            <div className="row d-md-flex d-none">
              <div className="col">
                <div className="job-details-card">
                  <h3 className="job-title">UI/UX Developer</h3>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="">
                      <div className="company mb-4" style={{ color: '#3d5f6f' }}>
                        <NavLink to="/">Ralph Lauren</NavLink>{' '}
                        <i
                          style={{
                            color: '#ffc107',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                          className="fa fa-star"
                          aria-hidden="true"
                        ></i>{' '}
                        <span className="rating">4.1</span> | <span className="reviews">35</span> Reviews{' '}
                      </div>
                      <div className="job-info" style={{ color: '#3d5f6f' }}>
                        {' '}
                        <span className="text-nowrap">
                          <i className="fa fa-briefcase" aria-hidden="true" style={{ marginRight: '8px' }}></i> 5 - 10 Years
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-inr" aria-hidden="true" style={{ marginRight: '8px' }}></i> Not Disclosed
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-map-marker" aria-hidden="true" style={{ marginRight: '8px' }}></i> Kolkata
                        </span>{' '}
                      </div>
                    </div>
                    <div className="text-end">
                      <NavLink
                        to="/login"
                        className="cmn_btn_fill"
                        style={{
                          borderRadius: '999px',
                          margin: 0,
                          display: 'inline-block',
                        }}
                      >
                        {' '}
                        Login to apply{' '}
                      </NavLink>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <hr />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-content-center">
                    <div className="posted">
                      {' '}
                      <span className="text-nowrap">
                        Posted: <strong>1 day ago</strong>
                      </span>{' '}
                      <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Opening: <strong>1</strong>
                      </span>
                      <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Applicants: <strong>31</strong>
                      </span>
                    </div>
                    <div className="">
                      <NavLink>Send me jobs like this</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-md-none d-flex">
              <div className="col">
                <div className="job-details-card">
                  <h3 className="job-title">UI/UX Developer</h3>
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="company mb-4" style={{ color: '#3d5f6f' }}>
                        <NavLink to="/">Ralph Lauren</NavLink>{' '}
                        <i
                          style={{
                            color: '#ffc107',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                          className="fa fa-star"
                          aria-hidden="true"
                        ></i>{' '}
                        <span className="rating">4.1</span> | <span className="reviews">35</span> Reviews{' '}
                      </div>
                      <div className="job-info" style={{ color: '#3d5f6f' }}>
                        <span className="text-nowrap">
                          <i className="fa fa-briefcase" aria-hidden="true" style={{ marginRight: '8px' }}></i> 5 - 10 Years
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-inr" aria-hidden="true" style={{ marginRight: '8px' }}></i> Not Disclosed
                        </span>{' '}
                        <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                        <span className="text-nowrap">
                          <i className="fa fa-map-marker" aria-hidden="true" style={{ marginRight: '8px' }}></i> Kolkata
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="posted">
                      {' '}
                      <span className="text-nowrap">
                        Posted: <strong>1 day ago</strong>
                      </span>{' '}
                      <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Opening: <strong>1</strong>
                      </span>
                      <span style={{ display: 'inline-block', marginInline: '10px' }}>|</span>{' '}
                      <span className="text-nowrap">
                        Applicants: <strong>31</strong>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-2 mt-4">
                    <div className="">
                      <NavLink
                        to="/login"
                        className="cmn_btn_fill"
                        style={{
                          borderRadius: '999px',
                          margin: 0,
                          display: 'inline-block',
                        }}
                      >
                        {' '}
                        Login to apply{' '}
                      </NavLink>
                    </div>
                    <div className="">
                      <NavLink>Send me jobs like this</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>Job Description: </h4>
                </div>
                <p>
                  {' '}
                  We are looking for a Business Analyst who can help maintain existing business with current clients, grow business relationships with them and assist in bringing new clients on
                  board.You will be responsible to work closely with Business and Technology teams, leading the understanding and successful implementation of technological solutions to business
                  requirements, designers and testers while being single point of contact to clients and senior management.{' '}
                </p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>Job Description: </h4>
                </div>
                <ul>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                  <li>
                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed, nulla? </p>
                  </li>
                </ul>
                <p>a. Lorem ipsum dolor sit amet.</p>
                <p>b. Lorem ipsum dolor sit amet.</p>
                <p>c. Lorem ipsum dolor sit amet.</p>
                <p>d. Lorem ipsum dolor sit amet.</p>
                <p>e. Lorem ipsum dolor sit amet.</p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}> Required Candidate Profile: </h4>
                </div>
                <p>
                  {' '}
                  We are looking for a Business Analyst who can help maintain existing business with current clients, grow business relationships with them and assist in bringing new clients on
                  board.You will be responsible to work closely with Business and Technology teams, leading the understanding and successful implementation of technological solutions to business
                  requirements, designers and testers while being single point of contact to clients and senior management.{' '}
                </p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>Why Join Us: </h4>
                </div>
                <p>
                  {' '}
                  We are looking for a Business Analyst who can help maintain existing business with current clients, grow business relationships with them and assist in bringing new clients on
                  board.You will be responsible to work closely with Business and Technology teams, leading the understanding and successful implementation of technological solutions to business
                  requirements, designers and testers while being single point of contact to clients and senior management.{' '}
                </p>
              </div>
              <div className="job-details">
                <p>
                  {' '}
                  <strong>Role:</strong> IT Project Lead{' '}
                </p>
                <p>
                  {' '}
                  <strong>Industry Type:</strong> IT Services & Consulting{' '}
                </p>
                <p>
                  {' '}
                  <strong>Department:</strong> Project & Program Management{' '}
                </p>
                <p>
                  {' '}
                  <strong>Employment Type:</strong> Full Time, Permanent{' '}
                </p>
                <p>
                  {' '}
                  <strong>Role Category:</strong> Technology / IT{' '}
                </p>
                <p>
                  {' '}
                  <strong>Compensation:</strong> 3.75-7.5 Lacs P.A.{' '}
                </p>
              </div>
              <div className="job-details">
                <div className="heading">
                  <h4 style={{ color: '#f25d23' }}>About Company: </h4>
                </div>
                <p>
                  {' '}
                  Sourcedesk Global ( <NavLink to="/">https://www.sourcedeskglobal.com</NavLink>) is one of the fastest-growing IT and Outsourcing solutions company head offices in Toronto and
                  Delivery Center in Kolkata. We are a group of young and energetic professionals with creativity and business ethics. Our customer-centric approach makes us highly creative in the use
                  of technology which insights in improved products and services. We help our clients with an appropriate mix of business consultation, technology, digital and advertising solutions
                  that integrate seamlessly with their marketing objectives, from start to finish. We are serving clients across Canada and US business markets.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="developer-cta section-cta-horizontal fix_padding full_width"
        style={{
          background: 'url(images/ai-ml-development-cta-horizontal-bg.jpg) center center / cover no-repeat',
        }}
      >
        <div className="container">
          <div className="row align-items-center gy-4">
            <div className="col-sm-8">
              <h2 className="text_controller">Current Job Openings</h2>
            </div>
            <div className="col-sm-4">
              <NavLink className="cmn_btn_fill text-nowrap cmn_btn_fill_white" to="/schedule-a-call">
                View All
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
