import { Helmet } from 'react-helmet';
import InnerBanner from '../Components/InnerBanner';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';

export default function CurrentOpenings() {
  return (
    <>
      <Helmet>
        <title>Current Openings</title>
        <meta name="description" content="Current Openings" />
      </Helmet>
      <InnerBanner
        InnBanner="images/current-openings-bg.jpg"
        bgColor="#fafbfd"
        bgRepeat="no-repeat"
        BnrRightImg="images/current-openings-right.png"
        BnrLeftImg=""
        TitleInn={['Find all ', <br />, <span>Current Openings</span>]}
        ParaInn=""
        priCTALink={''}
        priCTATitle={''}
        secCTALink={''}
        secCTATitle={''}
      />
      <section className="full_width fix_padding">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text_controller text_controller_center">
                <h2>
                  Current <span>Openings</span>
                </h2>
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="d-none d-lg-block accordion_def">
              <Openings />
            </div>
            <div className="d-block d-lg-none accordion_def">
              <OpeningsMob />
            </div>
          </div>
        </div>
      </section>
      <section className="full_width fix_padding" style={{ background: 'url(images/life-at-sdg-cta-bg-1.jpg) 50% 50% no-repeat rgb(27, 33, 45)' }}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
            <div className="text_controller">
              <h2 className="text-center text-sm-end pe-sm-3 pe-lg-4 border-right text-white">
                <span>READY TO</span>
                <br /> LEARN More?
              </h2>
            </div>
            <p className="ps-sm-3 ps-lg-4 me-sm-5 text-white text-center text-sm-start">Talk to an expert from Sourcedesk Global team.</p>
            <NavLink className="cmn_btn_fill text-nowrap ms-sm-5 cmn_btn_fill_white me-0" to="/">
              Begin Now
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}

function Openings() {
  return (
    <Tabs defaultActiveKey="all" id="fill-tab-example" className="mb-3 justify-content-center" fill>
      <Tab eventKey="all" title="All Jobs">
        <div className="d-flex job-listing">
          <div className="job-icon">
            <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
          </div>
          <div className="title me-2">
            <h3>Urgent Opening For - IT Business Analyst</h3>
            <p style={{ color: '#a2a2a2' }} className="text-nowrap experience">
              <i className="fa fa-briefcase" aria-hidden="true"></i> 2 to 10 years
            </p>
          </div>
          <div className="info d-flex align-items-center ms-auto">
            <div className="place text-nowrap">
              {' '}
              <i className="fa fa-map-marker me-2" aria-hidden="true"></i> Kolkata{' '}
            </div>
            <div className="posted-on text-nowrap">
              {' '}
              <i className="fa fa-calendar me-2" aria-hidden="true"></i> 31/04/2024{' '}
            </div>
            <NavLink to="/" className="cmn_btn_fill m-0 d-inline-block text-nowrap">
              View Details
            </NavLink>
          </div>
        </div>
        <div className="d-flex job-listing">
          <div className="job-icon">
            <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
          </div>
          <div className="title me-2">
            <h3>Urgent Hiring – AI/ML Developer (WFH)</h3>
            <p style={{ color: '#a2a2a2' }} className="text-nowrap">
              <i className="fa fa-briefcase" aria-hidden="true"></i> 2 to 10 years
            </p>
          </div>
          <div className="info d-flex align-items-center ms-auto">
            <div className="place text-nowrap">
              <i className="fa fa-map-marker me-2" aria-hidden="true"></i> Kolkata{' '}
            </div>
            <div className="posted-on text-nowrap">
              <i className="fa fa-calendar me-2" aria-hidden="true"></i> 31/04/2024{' '}
            </div>
            <NavLink to="/" className="cmn_btn_fill m-0 d-inline-block text-nowrap">
              View Details
            </NavLink>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
            Load More
          </NavLink>
        </div>
      </Tab>
      <Tab eventKey="bangalore" title="Openings in Bangalore"></Tab>
      <Tab eventKey="kolkata" title="Openings in Kolkata"></Tab>
      <Tab eventKey="remote" title="Openings in Remote"></Tab>
      <Tab eventKey="toronto" title="Openings in Toronto"></Tab>
    </Tabs>
  );
}
function OpeningsMob() {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>All Jobs</Accordion.Header>
        <Accordion.Body>
          <NavLink className="d-flex job-listing">
            <div className="job-icon">
              <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
            </div>
            <div className="title me-2">
              <h3>Urgent Opening For - IT Business Analyst</h3>
              <p style={{ color: '#a2a2a2' }} className="experience">
                <i className="fa fa-briefcase" aria-hidden="true"></i> 2 to 10 years <span className="mx-1">|</span> <i className="fa fa-map-marker me-2" aria-hidden="true"></i> Kolkata{' '}
                <span className="mx-1">|</span> <i className="fa fa-calendar me-2" aria-hidden="true"></i> 31/04/2024
              </p>
            </div>
          </NavLink>
          <NavLink className="d-flex job-listing">
            <div className="job-icon">
              <i className="fa fa-briefcase" aria-hidden="true"></i>{' '}
            </div>
            <div className="title me-2">
              <h3>Urgent Opening For - IT Business Analyst</h3>
              <p style={{ color: '#a2a2a2' }} className="experience">
                <i className="fa fa-briefcase" aria-hidden="true"></i> 2 to 10 years <span className="mx-1">|</span> <i className="fa fa-map-marker me-2" aria-hidden="true"></i> Kolkata{' '}
                <span className="mx-1">|</span> <i className="fa fa-calendar me-2" aria-hidden="true"></i> 31/04/2024
              </p>
            </div>
          </NavLink>
          <div className="d-flex justify-content-center">
            <NavLink to="/" className="cmn_btn_fill m-0 text-nowrap">
              Load More
            </NavLink>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Openings in Bangalore</Accordion.Header>
        <Accordion.Body></Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
