// import Header from "./Components/Header";
import Home from './pages/Home';
import About from './pages/About';
import { Routes, Route, useLocation } from 'react-router-dom';
import Footer from './Components/Footer';
import DistinctVettingProcess from './pages/DistinctVettingProcess';
import ReactJs from './pages/ReactJs';
import Blogs from './pages/Blogs';
import BlogDetails from './pages/BlogDetails';
import Events from './pages/Events';
import EventDetails from './pages/EventDetails';
import OurTeam from './pages/OurTeam';
import CustomerReview from './pages/CustomerReview';
// import ScrollToTop from "./Components/ScrollToTop";
import { useEffect } from 'react';
// import { Helmet } from "react-helmet";
import SiteMap from './pages/SiteMap';
import HowItWorks from './pages/HowItWorks';
import SDGAdvantage from './pages/SDGAdvantage';
import Pricing from './pages/Pricing';
import Developers from './pages/Developers';
import DigitalMarketers from './pages/DigitalMarketers';
import AccountantsFinance from './pages/AccountantsFinance';
import VirtualAssistant from './pages/VirtualAssistant';
import QATester from './pages/QATester';
import AiMl from './pages/AiMl';
import AiMlDevelopment from './pages/AiMlDevelopment';
import HireAiMlDeveloper from './pages/HireAiMlDeveloper';
import ApplyAsTalent from './pages/ApplyAsTalent';
import ScheduleACall from './pages/ScheduleACall';
import Login from './pages/Login';
import ContactUs from './pages/ContactUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TOS from './pages/TOS';
import HireDevelopmentTeam from './pages/HireDevelopmentTeam';
import JobDescription from './pages/JobDescription';
import EmployerReferral from './pages/EmployerReferral';
import EmployeeReferral from './pages/EmployeeReferral';
import ResumeGuide from './pages/ResumeGuide';
import SocialResponsibility from './pages/SocialResponsibility';
import Career from './pages/Career';
import NotFound from './pages/NotFound';
import HeaderV2 from './Header/HeaderV2';
import CustomSoftwareDevelopment from './pages/CustomSoftwareDevelopment';
import Designer from './pages/Designer';
import SketchArtist from './pages/SketchArtist';
import GraphicsDesigner from './pages/GraphicsDesigner';
import ArtificialIntelligence from './pages/ArtificialIntelligence';
import LifeAtSDG from './pages/LifeAtSDG';
import ThankYou from './pages/ThankYou';
import Internship from './pages/Internship';
import Openings from './pages/Openings';
import CurrentOpenings from './pages/CurrentOpenings';
// import Navigation from "./Header/Navigation";
// import Loader from "./Components/Loader";
// import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();
  // console.log(pathname);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto', // Use 'auto' for immediate scrolling without smooth animation
    });
  }, [pathname]);
  return null;
}

// export default ScrollToTop;

function App() {
  // const [loading, setLoading] = useState(true);
  // const location = useLocation();

  // useEffect(() => {
  //   // Show loader when URL changes
  //   setLoading(true);
  // }, [location.pathname]); // Watch for changes in pathname

  // useEffect(() => {
  //   // Hide loader when page finishes loading
  //   const handleLoad = () => setLoading(false);
  //   window.addEventListener('load', handleLoad);

  //   // Hide loader when page is scrolled
  //   const handleScroll = () => {
  //     setLoading(false);
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('load', handleLoad);
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  // const router = createBrowserRouter([
  //   {
  //     path: "/",
  //     element: <Home />,
  //   },

  //   {
  //     path: "/about",
  //     element: <About />,
  //   },
  // ])

  return (
    <>
      <ScrollToTop />
      {/* <Header /> */}
      <HeaderV2 />
      {/* <Navigation /> */}
      <Routes>
        {/*<Route path="/" element={} />*/} {/* Scroll to top on every route change */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/distinct-vetting-process" element={<DistinctVettingProcess />} />
        <Route path="/react-js" element={<ReactJs />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog-details/:id" element={<BlogDetails />} />
        <Route path="/events" element={<Events />} />
        <Route path="/event-detail/:id" element={<EventDetails />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/customer-review" element={<CustomerReview />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/sdg-advantage" element={<SDGAdvantage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/digital-marketers" element={<DigitalMarketers />} />
        <Route path="/accountants-finance" element={<AccountantsFinance />} />
        <Route path="/virtual-assistant" element={<VirtualAssistant />} />
        <Route path="/virtual-assistant" element={<VirtualAssistant />} />
        <Route path="/qa-tester" element={<QATester />} />
        <Route path="/ai-ml" element={<AiMl />} />
        <Route path="/ai-ml-development" element={<AiMlDevelopment />} />
        <Route path="/hire-ai-ml-developer" element={<HireAiMlDeveloper />} />
        <Route path="/apply-as-talent" element={<ApplyAsTalent />} />
        <Route path="/schedule-a-call" element={<ScheduleACall />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-service" element={<TOS />} />
        <Route path="/hire-development-team" element={<HireDevelopmentTeam />} />
        <Route path="/job-description" element={<JobDescription />} />
        <Route path="/employer-referral" element={<EmployerReferral />} />
        <Route path="/employee-referral" element={<EmployeeReferral />} />
        <Route path="/resume-guide" element={<ResumeGuide />} />
        <Route path="/social-responsibility" element={<SocialResponsibility />} />
        <Route path="/career" element={<Career />} />
        <Route path="/sitemap" element={<SiteMap />} />
        <Route path="/custom-software-development" element={<CustomSoftwareDevelopment />} />
        <Route path="/services" element={<CustomSoftwareDevelopment />} />
        <Route path="/services/:id" element={<CustomSoftwareDevelopment />} />
        <Route path="/designer" element={<Designer />} />
        <Route path="/hire-our-genius-mind" element={<Designer />} />
        <Route path="/hire-our-genius-mind/:id" element={<Designer />} />
        <Route path="/sketch-artist" element={<SketchArtist />} />
        <Route path="/graphics-designer" element={<GraphicsDesigner />} />
        <Route path="/artificial-intelligence" element={<ArtificialIntelligence />} />
        <Route path="/life-at-sdg" element={<LifeAtSDG />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/internship" element={<Internship />} />
        <Route path="/openings" element={<Openings />} />
        <Route path="/current-openings" element={<CurrentOpenings />} />
        {/* <Route path="/test" element={<HeaderV2 />} /> */}
        {/* Add a catch-all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
