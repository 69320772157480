import { NavLink } from "react-router-dom";
import InnerBanner from "../Components/InnerBanner";
import TrustedSlider from "../Components/TrustedSlider";
import { Helmet } from "react-helmet";

export default function ApplyAsTalent() {
  return <>
  <Helmet>
  <title>Be a Valuable Pre-Vetted Resource - Apply As a Talent | Sourcedesk</title>
<meta name="description" content="Become a valuable resource! Apply as talent at Sourcedesk. Join our pre-vetted community for rewarding opportunities. Your journey to success starts here." />

{/* <!-- Head  -->
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NVLH3JKB');</script>
<!-- End Google Tag Manager -->
<!-- Required meta tags -->
<meta charset="utf-8">
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
<meta name="google-site-verification" content="H6B0Yhz76GdiqjxekYyzCSTY94kBUEyyCFzGURiRZVw ">
<meta name="robots" content="index,follow" />

<!-- Title Bar Icon --> */}
<link rel="icon" href="https://www.sourcedesk.io/public/images/favicon.png" type="image/x-icon" />
  </Helmet>
    <InnerBanner
        InnBanner="images/apply_banner_bg.jpg"
        bgRepeat="no-repeat"
        bgColor="#fafbfd"
        BnrRightImg="images/apply_banner_right_img.png"
        TitleInn={[<span>Boost Your Software Career</span>,<br />," Secure Your Future with Remote Work"]}
        ParaInn="Join elite developers' network for high-paying remote jobs in top Canadian and American companies."
        priCTALink="https://www.sourcedeskglobal.com/current-openings" priCTATitle="Take the First Step Towards Success" secCTALink={""} secCTATitle={""}
      />
    <TrustedSlider defaultTitle={"Trusted By"} />
    <section className="full_width SDGvsOTHERS apply_job_position fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2><span>Join Our Dynamic Team</span> <br /> Exciting Remote Jobs with Growth Opportunities</h2>
                <hr />
            </div>
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Role</th>
                            <th scope="col">Jobs Offered</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{width: "30%"}}>Developer</th>
                            <td style={{width: "70%"}}>Full-stack developer, Front-end developer, Back-end developer, Mobile developer, DevOps Engineer, Software Engineer, UI/UX Designer, Database Administrator</td>
                        </tr>
                        <tr>
                            <th style={{width: "30%"}}>Tester</th>
                            <td style={{width: "70%"}}>Manual Tester, Automation Tester, Performance Tester, Security Tester, Mobile Tester, Test Manager, Test Architect, Test Engineer</td>
                        </tr>
                        <tr>
                            <th style={{width: "30%"}}>Digital Marketer</th>
                            <td style={{width: "70%"}}>SEO Specialist, Social Media Specialist, PPC Specialist, Email Marketing Specialist, Content Marketing Specialist</td>
                        </tr>
                        <tr>
                            <th style={{width: "30%"}}>Accountant and Finance</th>
                            <td style={{width: "70%"}}>Financial Analyst, Accountant, Bookkeeper, Payroll Specialist, Tax Accountant</td>
                        </tr>
                        <tr>
                            <th style={{width: "30%"}}>Virtual Assistant</th>
                            <td style={{width: "70%"}}>Administrative Assistant, Personal Assistant, Data Entry Specialist, Research Assistant, E-Commerce Assistant</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
    <section className="full_width We_harness appy_why_choose fix_padding" data-inviewport="scale-in">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2><span>Unlock Your Potential</span><br /> Discover Unique Benefits and Perks for Our Candidates</h2>
                <hr />
            </div>
            <div className="row We_harness_bottom">
                <div className="col-12 col-md-6 col-sm-5 col-xl-5 We_hrns_image"> <svg viewBox="0 0 610 610">
                        <circle cx="306" cy="292" r="290" fill="none"></circle>
                    </svg>
                    <div className="We_hrns_image_grp"> <img src="images/apply_why_choose.png" alt="Flower" className="We_hrns_image_main" /> </div>
                </div>
                <div className="col-12 col-md-6 col-sm-12 col-xl-7 We_hrns_usp">
                    <ul>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon1.png" alt="" /></div>
                            <h4>Comprehensive Medical Benefits</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon2.png" alt="" /></div>
                            <h4>Performance &amp; Review Bonus Program</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon3.png" alt="" /></div>
                            <h4>Access to Prestigious Co-Working Spaces</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon4.png" alt="" /></div>
                            <h4>Daily Meals and Additional Convenience Perks</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon5.png" alt="" /></div>
                            <h4>Complimentary Gym Membership</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon6.png" alt="" /></div>
                            <h4>Dedicated Onboarding and Ongoing Support </h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon7.png" alt="" /></div>
                            <h4>Extensive Training and Development Opportunities</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon8.png" alt="" /></div>
                            <h4>Exciting ICT Opportunities to Work Abroad</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon9.png" alt="" /></div>
                            <h4>State-of-the-Art Computers &amp; Accessories</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon10.png" alt="" /></div>
                            <h4>Engaging Company Events &amp; Perks</h4>
                        </li>
                        <li>
                            <div className="We_hrns_usp_icon"><img src="images/harness_icon11.png" alt="" /></div>
                            <h4>Team Building Retreats and Recognition Awards</h4>
                        </li>
                    </ul>
                    <div className="cmn_btn_grp"><NavLink to="https://www.sourcedeskglobal.com/current-openings" target="_blank" className="cmn_btn_fill">Seize the Opportunity</NavLink></div>
                </div>
            </div>
        </div>
    </section>
    <section className="full_width Human_img apply_skills fix_padding">
        <div className="container">
            <div className="text_controller">
                <h2><span>Join Our Premier Community</span> <br />Essential Skills for Success and Excellence</h2>
                <hr />
            </div>
            <div className="row Human_img_bottom">
                <div className="col-12 col-md-7 col-sm-7 col-xl-7 Hmn_img_bt_left">
                    <ul>
                        <li>
                            <div className="Hmn_bt_left_border"></div>
                            <div className="Hmn_bt_left_text">Exceptional Communication Skills</div>
                        </li>
                        <li>
                            <div className="Hmn_bt_left_border"></div>
                            <div className="Hmn_bt_left_text">Profound Technical Proficiency</div>
                        </li>
                        <li>
                            <div className="Hmn_bt_left_border"></div>
                            <div className="Hmn_bt_left_text">Cultural Alignment </div>
                        </li>
                        <li>
                            <div className="Hmn_bt_left_border"></div>
                            <div className="Hmn_bt_left_text">Strong Problem-Solving Abilities</div>
                        </li>
                        <li>
                            <div className="Hmn_bt_left_border"></div>
                            <div className="Hmn_bt_left_text">Effective Self-Discipline and Time Management </div>
                        </li>
                    </ul>
                    <div className="cmn_btn_grp"><NavLink to="https://www.sourcedeskglobal.com/current-openings" target="_blank" className="cmn_btn_fill">Take Action Now</NavLink></div>
                </div>
                <div className="col-12 col-md-5 col-sm-5 col-xl-5 Hmn_img_bt_right"><img src="images/apply_skill_right_img.png" alt="" /></div>
            </div>
        </div>
    </section>
    <section className="full_width talent_sec our_success fix_padding">
        <div className="container">
            <div className="talent_sec_top">
                <div className="tlnt_sec_top_left text_controller">
                    <h2>Inspiring Stories from Thriving Candidates <br /> <span> Real Testimonials, Tangible Outcomes</span></h2>
                </div>
                <div className="tlnt_sec_top_right">
                    <p> Real Testimonials, <br /> Tangible Outcomes</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 our_success_text"> <img src="images/promotion.png" alt="" />
                    <h2>Wilson James Taylor, Senior AI/ML Developer</h2>
                    <p>As an experienced AI/ML developer, I was looking for a role that would challenge me and allow me to work on cutting-edge projects. Sourcedesk Global's team truly understood my skillset and career aspirations. They took the time to understand my goals and matched me with a company that was the perfect fit. Their streamlined process made the entire job search hassle-free, and I'm now working on projects that excite me and align with my expertise.</p>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 our_success_text"> <img src="images/promotion.png" alt="" />
                    <h2>Emily Johnson, Node.js Developer</h2>
                    <p>Finding the right job can be a daunting task, but Sourcedesk Global made the process seamless for me. Their team's deep understanding of the Node.js ecosystem and their extensive network of companies allowed them to match me with a role that perfectly complemented my skills and experience level. I was impressed by their personalized approach and their commitment to ensuring a mutually beneficial fit for both the candidate and the employer.</p>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 our_success_text"> <img src="images/promotion.png" alt="" />
                    <h2>Michael Thompson, QA Tester</h2>
                    <p>As a recent graduate in the field of software testing, I was unsure of how to navigate the job market. Sourcedesk Global's team provided invaluable guidance and support throughout the entire process. They took the time to understand my strengths, interests, and career goals and then matched me with a company that not only aligned with my skill set as a QA tester but also provided me with the opportunity to learn and grow. Thanks to their expertise, I landed my dream job and am now part of an amazing team working on cutting-edge projects.</p>
                </div>
            </div>
        </div>
    </section>
    <section className="full_width Hiring_Genius remote_dvloper apply_talent fix_padding">
        <div className="container">
            <div className="text_controller text_controller_center">
                <h2>Join the <span>SDG</span> Talent Community in 4 Simple Steps</h2>
                <hr />
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-sm-4 col-xl-4 col-lg-6 Hrng_Gns_left">
                    <ul>
                        <li>
                            <h2>Create Your profile</h2>
                            <p>Share your name, skills, location, salary, and experience.</p>
                        </li>
                        <li>
                            <h2>Take Assessments and Interviews</h2>
                            <p>Answer questions and participate in technical interviews.</p>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-4 col-sm-4 col-xl-4 col-lg-4  Hrng_Gns_filter"> <img src="images/apply_talent_img.png" alt="Filter" /> </div>
                <div className="col-12 col-md-6 col-sm-4 col-xl-4 col-lg-6 Hrng_Gns_right">
                    <ul>
                        <li>
                            <h2>Receive Job Offers</h2>
                            <p>Get matched with premier US and Canadian companies.</p>
                        </li>
                        <li>
                            <h2>Launch Your Dream Career</h2>
                            <p>End your job hunting journey with Sourcedesk Global</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="cmn_btn_grp cmn_btn_grp_center"> <NavLink to="https://www.sourcedeskglobal.com/current-openings" target="_blank" className="cmn_btn_fill">Initiate Your Progress</NavLink> </div>
        </div>
    </section>
  </>
}
